import { createStore }  from 'vuex'

import common           from './components/common';
import api              from './components/api';
import events           from './components/events';

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        common,
        api,
        events
    }
})
