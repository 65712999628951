import store from '../../store/index'
import router from '../index'

export default function (to, from, next) {
	const location = store.getters.getLocations.find((i) => i.link == to.params.location);
    if ( typeof location != 'undefined' ){
		if (store.getters.getLocationStatus(location.id)){
			next();
		}
        else{
			store.dispatch('setLoading', true);
			store.dispatch('getEvents', location.id).then((ret) => {
				if (store.getters.getError == null){
					store.dispatch('setEvents', {catalog: ret.data.catalog, location: location});
					store.dispatch('setLoading', false);
					next();
				}
			});
		}
    }
    else{
        next('/404');
    }
}
