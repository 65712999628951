export default {
    state: {
        auth: false,
        loading: true,
        error: null,
        system_settings: {},
        system_messages: {},
        header: {
            fullHidden: true,
            show: false,
            step: 1,
            progress: '0%'
        },
    },
    mutations: {
        setAuth (state, payload) {
            state.auth = true;
            state.system_settings = payload.system_settings;
            state.system_messages = payload.system_messages;
        },
        setLoading (state, payload) {
            state.loading = payload;
        },
        setError (state, payload) {
            state.error = payload;
        },
        setHeader (state, payload) {
            state.header = payload;
        }
    },
    actions: {
        setAuth ({commit}, payload) {
            commit('setAuth', payload);
        },
        setLoading ({commit}, payload) {
            commit('setLoading', payload);
        },
        setError ({commit}, payload) {
            commit('setError', payload);
        },
        setHeader ({commit}, payload) {
            commit('setHeader', payload);
        }
    },
    getters: {
        getAuth (state) {
            return state.auth;
        },
        getLoadingStatus (state) {
            return state.loading;
        },
        getError (state) {
            return state.error;
        },
        getMessages (state) {
            return state.system_messages;
        },
        getSettings (state) {
            return state.system_settings;
        },
        getHeader (state) {
            return state.header;
        }
    }
}
