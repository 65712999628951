import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const bookingApp = createApp(App);
import "./scss/style.scss";

var mixin = {
    methods: {
        normalizeArray(obj){
            if (typeof obj == 'undefined' || obj == null) return [];
            if (typeof obj == 'object' && !Array.isArray(obj)) return Object.values(obj);
            if (typeof obj == 'object' && Array.isArray(obj)) return obj;
            return [];
        },
        connvertToHumanDate (timestamp){
            const date = new Date(timestamp * 1000);
            return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
        },
        timestamp_to_date(timestamp){
            return new Intl.DateTimeFormat('ru-RU',{
                hour: "numeric",
                minute: "numeric",
                day: "numeric",
                month: "numeric",
                year: "numeric"
            }).format(timestamp*1000);
        },
        decodeHTMLEntities(text){
            if (typeof text == 'undefined' || text == '' || text == null) return '';
            const entities = {
                'amp': '&',
                'apos': '\'',
                '#x27': '\'',
                '#x2F': '/',
                '#39': '\'',
                '#47': '/',
                'lt': '<',
                'gt': '>',
                'nbsp': ' ',
                'quot': '"'
            };
            return text.replace(/&([^;]+);/gm, function (match, entity) {
                return entities[entity] || match
            });
        },
        formatMoney(amount, thousands = ",", decimalCount = 0, decimal = ".") {
            try {
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
                const negativeSign = amount < 0 ? "-" : "";
                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;
                return negativeSign +
                    (j ? i.substr(0, j) + thousands : '') +
                    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
                    (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            } catch (e) {
                console.log(e)
            }
        }
    }
};

bookingApp.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
});

bookingApp
    .mixin(mixin)
    .use(store)
    .use(router)
    .mount('#app')
