<template>
	<div v-if="!header.fullHidden">
		<div class="md:mt-8 mb-5 w-full h-36 md:h-38 xl:h-38 hidden md:flex rounded-b-3xl md:rounded-t-3xl justify-center items-center bg-cover bg-center" :style="'background-image:url(' + location.hero_img + ')'">
			<LogoW size="big" />
		</div>
		<div class="flex md:hidden justify-between items-center pl-4 pr-5 pb-3 mt-3 mb-6 border-b">
			<LogoB size="small" />
			<router-link to="/" class=""><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 7.5C2.5 6.80964 3.05964 6.25 3.75 6.25H26.25C26.9404 6.25 27.5 6.80964 27.5 7.5C27.5 8.19036 26.9404 8.75 26.25 8.75H3.75C3.05964 8.75 2.5 8.19036 2.5 7.5ZM2.5 15C2.5 14.3096 3.05964 13.75 3.75 13.75H26.25C26.9404 13.75 27.5 14.3096 27.5 15C27.5 15.6904 26.9404 16.25 26.25 16.25H3.75C3.05964 16.25 2.5 15.6904 2.5 15ZM2.5 22.5C2.5 21.8096 3.05964 21.25 3.75 21.25H26.25C26.9404 21.25 27.5 21.8096 27.5 22.5C27.5 23.1904 26.9404 23.75 26.25 23.75H3.75C3.05964 23.75 2.5 23.1904 2.5 22.5Z" fill="#333333"/></svg></router-link>
		</div>
		<div v-if="header.show" class="px-5">
			<div class="w-full max-w-xl bg-system rounded-full h-2.5 m-auto mb-1">
				<div class="bg-progressFill h-2.5 rounded-full transition-all duration-300" :style="'width:' + header.progress"></div>
			</div>
			<div class="w-full max-w-xl m-auto flex justify-between text-xs text-disabled mb-8 md:mb-6">
				<span :class="'transition-colors duration-300' + ((header.step == 1 || header.step == 2 || header.step == 3) ? ' text-black' : '')">{{ messages.event_type }}</span>
				<span :class="'transition-colors duration-300' + ((header.step == 2 || header.step == 3) ? ' text-black' : '')">{{ messages.details }}</span>
				<span :class="'transition-colors duration-300' + ((header.step == 3) ? ' text-black' : '')">{{ messages.date_and_location }}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import LogoW from '../components/icons/logo_w.vue';
	import LogoB from '../components/icons/logo_b.vue';
	export default {
		components: {
			LogoW,
			LogoB
		},
		computed: {
			location () {
				return this.$store.getters.getLocation;
			},
			messages () {
				return this.$store.getters.getMessages;
			},
			header () {
				return this.$store.getters.getHeader;
			},
		},
	};
</script>