import store from '../../store/index'

export default function (to, from, next) {
	const parent_item = store.getters.getCatalog.find((i) => i.id == to.params.catalogId);
	let found = false;
	store.dispatch('setCurrentEvent', {});
	if ( typeof parent_item.catalog != 'undefined' ){
		parent_item.catalog.forEach((element) => {
			if ( typeof element.events.find((i) => i.id == to.params.eventId) != 'undefined' ){
				found = true;
				store.dispatch('setCurrentEvent', element.events.find((i) => i.id == to.params.eventId));
			}
		});
	}
	else{
		if ( typeof parent_item.events.find((i) => i.id == to.params.eventId) != 'undefined' ){
			found = true;
			store.dispatch('setCurrentEvent', parent_item.events.find((i) => i.id == to.params.eventId));
		}
	}

    if ( found ){
		next();
    }
    else{
        next('/404');
    }
}