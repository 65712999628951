<template>
    <div class="app_container container">
        <Loading />
        <Header />
        <router-view />
    </div>
</template>

<script>

import Loading  from './components/loading';
import Header   from './components/header.vue';

export default {
    name: "App",
    components: {
        Loading,
        Header
    },
    data () {
        return{

        }
    },
    methods: {

    },
    mounted: function() {

    },
    computed: {

    },
    beforeDestroy(){

    },
    created () {

    }
};
</script>
