import store from '../../store/index'

export default function (to, from, next) {
    if (store.getters.getAuth) {
        next();
    } else {
        store.dispatch('setLoading', true);
        store.dispatch('getInitApp').then((ret) => {
            if (store.getters.getError == null){
                store.dispatch('setAuth', ret.data);
                store.dispatch('setLocations', ret.data);
                store.dispatch('setLoading', false);
                next();
            }
        });
    }
}
